
import "survey-creator-core/survey-creator-core.min.css";
import "survey-core/defaultV2.min.css";
import "bootstrap/dist/css/bootstrap.css";

import './App.css';
import { json } from "./JSON.js";
import { Model } from "survey-core";
import React, {useEffect, useState} from "react";
import { Survey } from "survey-react-ui";
import Footer from "./Footer";
import constants from "./constants";
import { surveyLocalization } from "survey-core";
import Swal from "sweetalert2";

function App() {


  // const creatorOptions = {
  //   showLogicTab: true,
  //   isAutoSave: true
  // };

  const engLocale = surveyLocalization.locales["en"];
  engLocale.pagePrevText = "Regresar";
  engLocale.pageNextText = "Siguiente";
  engLocale.completeText = "Enviar";
  engLocale.yes = "Si";

  const customLocaleStrings = {
    pagePrevText: "Regresar",
    pageNextText: "Siguiente",
    completeText: "Enviar",
    yes: "Si"
};
// surveyLocalization.strings["en"].yes = "Si";


useEffect(() => {
  surveyLocalization.locales["en"].yes = "Si";
}, []);

surveyLocalization.locales["my"] = customLocaleStrings;
  
  const submitSurvey = async (sender) => {
    try {
      const rawResponse = await fetch(constants.api + "nodemailer/", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          ApellidoPaterno: sender?.data?.ApellidoPaterno ?? 'n/a',
          ApellidoMaterno: sender?.data?.ApellidoMaterno ?? 'n/a',
          Nombre: sender?.data?.Nombre ?? 'n/a',
          Domicilio: sender?.data?.Domicilio ?? 'n/a',
          Tel: sender?.data?.Tel ?? 'n/a',
          CorreoD: sender?.data?.CorreoD ?? 'n/a',
          PuestoSP: sender?.data?.PuestoSP ?? 'n/a',
          AreaSP: sender?.data?.AreaSP ?? 'n/a',
          NombreJefe: sender?.data?.NombreJefe ?? 'n/a',
          FechaHechos: sender?.data?.FechaHechos ?? 'n/a',
          LugarHechos: sender?.data?.LugarHechos ?? 'n/a',
          HoraHechos: sender?.data?.HoraHechos ?? 'n/a',
          DescripcionHechos: sender?.data?.DescripcionHechos ?? 'n/a',
          DescripcionHechosArchiv: sender?.data?.DescripcionHechosArchiv ?? 'n/a',
          TestigoSP: sender?.data?.TestigoSP ?? 'n/a',
          ApellidoMTestigo: sender?.data?.ApellidoMTestigo ?? 'n/a',
          ApellidoPTestigo: sender?.data?.ApellidoPTestigo ?? 'n/a',
          NombreTestigo: sender?.data?.NombreTestigo ?? 'n/a',
          DomicilioTestigo: sender?.data?.DomicilioTestigo ?? 'n/a',
          TelTestigo: sender?.data?.TelTestigo ?? 'n/a',
          CorreoTestigo: sender?.data?.CorreoTestigo ?? 'n/a',
          NombreSPR: sender?.data?.NombreSPR ?? 'n/a',
          PuestoSPR: sender?.data?.PuestoSPR ?? 'n/a',
          NombreJefeI: sender?.data?.NombreJefeI ?? 'n/a',
          MediosP: sender?.data?.MediosP ?? 'n/a',
          LocalizacionMedios: sender?.data?.LocalizacionMedios ?? 'n/a',
          
          // image: sender?.data?.image ?? 'n/a'

        }),
    });
    const result = await rawResponse.json();

 
    console.log('Entro al result ', result);
    Swal.fire(
      'Denuncia enviada',
      'Click para terminar',
      'success'
    )
    } catch (error) {
      // alert('Hay un error', error)
      console.log('ERROR ',error);
    }
};
  // const creator = new SurveyCreator(creatorOptions);

  const survey = new Model(json);
  // survey.onComplete.add((sender, options) => {
  //     console.log(JSON.stringify(sender.data, null, 3));
  // });
  

  survey.onComplete.add ((sender, options) => {
      console.log(JSON.stringify(sender.data, null, 3));
      console.log(sender?.data)
      ;
   
      submitSurvey(sender)
    


      
  });
  return (
    <div className="App">
      <Survey model={survey} /> 
       <Footer /> 
    </div>
  );
}

export default App;
