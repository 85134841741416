export const json =
{
    "locale": "es",
    "title": "Anexo",
    "description": "FORMATO PRESENTACIÓN DE DENUNCIA",
    "logoPosition": "right",
    "completedHtml": {
     "es": "<h3>¡ Tu información se envió correctamente !</h3>"
    },
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "panel",
        "name": "panel1",
        "elements": [
         {
          "type": "text",
          "name": "ApellidoPaterno",
          "startWithNewLine": false,
          "title": "Apellido Paterno:",
          "hideNumber": true
         },
         {
          "type": "text",
          "name": "ApellidoMaterno",
          "startWithNewLine": false,
          "title": "Apellido Materno:",
          "hideNumber": true
         },
         {
          "type": "text",
          "name": "Nombre",
          "startWithNewLine": false,
          "title": "Nombre(s):",
          "hideNumber": true
         }
        ]
       },
       {
        "type": "panel",
        "name": "panel3",
        "elements": [
         {
          "type": "text",
          "name": "CorreoD",
          "title": {
           "default": "Apellido Materno:",
           "es": "Correo electrónico para recibir notificaciones:"
          },
          "hideNumber": true,
          "isRequired": true
         },
         {
          "type": "text",
          "name": "Domicilio",
          "startWithNewLine": false,
          "title": {
           "default": "Apellido Paterno:",
           "es": "Domicilio"
          },
          "hideNumber": true
         }
        ]
       },
       {
        "type": "text",
        "name": "Tel",
        "title": "Teléfono (extensión en su caso):",
        "hideNumber": true,
        "inputType": "tel"
       },
       {
        "type": "panel",
        "name": "panel5"
       }
      ],
      "title": {
       "es": "Parte 1. Datos de la persona que presenta la denuncia"
      },
      "description": {
       "es": " (Si su denuncia es anónima, estos datos son opcionales, a excepción del domicilio para recibir notificaciones)."
      }
     },
     {
      "name": "page2",
      "elements": [
       {
        "type": "text",
        "name": "PuestoSP",
        "title": "Puesto o cargo que desempeña:",
        "hideNumber": true
       },
       {
        "type": "text",
        "name": "AreaSP",
        "title": "Área de adscripción:",
        "hideNumber": true
       },
       {
        "type": "text",
        "name": "NombreJefe",
        "title": {
         "default": "Nombre del jefe inmediato:",
         "es": "Nombre del jefe(a) inmediato(a):"
        },
        "hideNumber": true
       }
      ],
      "description": "En caso de ser persona servidora pública proporcione los siguientes datos:"
     },
     {
      "name": "page3",
      "elements": [
       {
        "type": "panel",
        "name": "panel6",
        "elements": [
         {
          "type": "text",
          "name": "FechaHechos",
          "title": {
           "default": "Fecha en la que ocurren los hechos:",
           "es": "Fecha en la que ocurrieron los hechos:"
          },
          "hideNumber": true,
          "isRequired": true,
          "requiredErrorText": {
           "es": "Este campo es requerido"
          },
          "inputType": "date"
         },
         {
          "type": "text",
          "name": "LugarHechos",
          "startWithNewLine": false,
          "title": "Lugar(es) en que ocurrieron los hechos:",
          "hideNumber": true,
          "isRequired": true,
          "requiredErrorText": {
           "es": "Este campo es requerido"
          }
         },
         {
          "type": "text",
          "name": "HoraHechos",
          "startWithNewLine": false,
          "title": {
           "default": "Hora:",
           "es": "Hora aproximada:"
          },
          "hideNumber": true,
          "isRequired": true,
          "requiredErrorText": {
           "es": "Este campo es requerido"
          },
          "inputType": "time"
         }
        ]
       },
       {
        "type": "comment",
        "name": "DescripcionHechos",
        "title": "Descripción de los hechos:",
        "hideNumber": true,
        "isRequired": true,
        "requiredErrorText": {
         "es": "Este campo es requerido"
        },
        "placeholder": {
         "es": " ¿Cómo? Conducta que la persona denunciada realizó. ¿Cuándo? Fecha y hora, las cuales no necesariamente deben ser exactas. ¿Dónde? Lugar, dirección o en su caso, una aproximación. ¿Cuál? Principio, valor o regla de integridad presuntamente vulnerada."
        }
       }
      ],
      "title": {
       "es": "Parte 2. Relato de los hechos"
      },
      "description": {
       "default": "Relato de los hechos",
       "es": "Por favor relatar detalladamente los hechos ¿Cómo? ¿Cuándo?¿Dónde? "
      }
     },
     {
      "name": "page4",
      "elements": [
       {
        "type": "boolean",
        "name": "TestigoSP",
        "title": {
         "default": "¿El o la testigo es persona servidora pública?",
         "es": "¿Él o la testigo es persona servidora pública?"
        },
        "hideNumber": true,
        "requiredErrorText": {
         "es": "Este campo es requerido"
        },
        "labelTrue": {
         "es": "(SÍ)"
        },
        "labelFalse": {
         "es": "(No)"
        }
       },
       {
        "type": "panel",
        "name": "panel4",
        "elements": [
        {
        "type": "text",
        "name": "ApellidoPTestigo",
        "title": "Apellido Paterno:",
        "hideNumber": true
        },
         {
          "type": "text",
          "name": "ApellidoMTestigo",
          "title": "Apellido Materno:",
          "startWithNewLine": false,
          "hideNumber": true
         },
         {
          "type": "text",
          "name": "NombreTestigo",
          "startWithNewLine": false,
          "title": "Nombre(s):",
          "hideNumber": true
         }
        ]
       },
       {
        "type": "text",
        "name": "DomicilioTestigo",
        "title": "Domicilio:",
        "hideNumber": true
       },
       {
        "type": "panel",
        "name": "panel7",
        "elements": [
         {
          "type": "text",
          "name": "TelTestigo",
          "title": "Teléfono(extensión en su caso):",
          "hideNumber": true
         },
         {
          "type": "text",
          "name": "CorreoTestigo",
          "startWithNewLine": false,
          "title": "Correo electrónico:",
          "hideNumber": true,
          "requiredErrorText": {
           "es": "Este campo requiere correo electrónico"
          },
          "inputType": "email"
         }
        ]
       }
      ],
      "title": {
       "es": "Parte 3. Datos de testigos"
      },
      "description": {
       "default": "Datos de testigos",
       "es": "(opcionales)"
      }
     },
     {
      "name": "page5",
      "elements": [
       {
        "type": "text",
        "name": "NombreSPR",
        "title": {
         "default": "Nombre de la Persona Servidora Pública sobre la que se presenta la queja o denuncia(presunto responsable):",
         "es": "Nombre, cargo (de ser posible): "
        },
        "description": {
         "es": "O bien cualquier otro elemento que la identifique como sitio de trabajo, número de ventanilla, horario, o características físicas de la persona."
        },
        "hideNumber": true,
        "isRequired": true,
        "requiredErrorText": {
         "es": "Este campo es requerido"
        }
       },
       {
        "type": "text",
        "name": "NombreJefeI",
        "title": "Nombre de su jefe (a) inmediato(a):",
        "hideNumber": true,
        "isRequired": true
       }
      ],
      "visible": false,
      "title": {
       "es": "Parte 4. Datos del personal servidor(a) público(a)  involucrado(a) o presunto responsable."
      },
      "description": "Datos del personal servidor(a) público(a)  involucrado(a) o presunto responsable"
     },
     {
      "name": "page6",
      "elements": [
       {
        "type": "text",
        "name": "MediosP",
        "title": {
         "default": "Mencionar cuáles",
         "es": "Mencionar cuáles, por ejemplo: Documentales, Mensajes de texto, Correos electrónicos, Videos, Fotografías, Audios o conversaciones, Nombre de Testigos (as):"
        },
        "hideNumber": true
       },
       {
        "type": "file",
        "name": "DescripcionHechosArchiv",
        "title": {
         "es": "En caso de contar con evidencias, favor de anexarlas concentradas en un solo archivo:"
        },
        "description": {
         "es": "Acepta archivos .doc .docx .pdf .zip . rar"
        },
        "hideNumber": true,
        "requiredErrorText": {
         "es": "El archivo máximo de 50MB"
        },
        "maxSize": 50000000
       },
       {
        "type": "text",
        "name": "LocalizacionMedios",
        "title": {
         "default": "En caso de no contar con ellos, indique dónde se localizan o a quién le constan",
         "es": "En caso de no contar con ellos, indique dónde se localizan o a quién le constan:"
        },
        "hideNumber": true
       }
      ],
      "title": {
       "es": "Parte 4. Medios Probatorios"
      },
      "description": {
       "default": "Medios Probatorios",
       "es": "Pueden ofrecer cualquier dato, elemento o indicio que apoye su narrativa. "
      }
     }
    ]
   }