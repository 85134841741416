import React from 'react';
import Button from "react-bootstrap/Button";
import logocontraloria from "./img/escudo.png";
import efacebook from "./img/Ico-facebook.png";
import einstragram from "./img/Ico-instagram.png";
import etwitter from "./img/Ico-twitter.png";
import eyoutube from "./img/Ico-youtube.png";
import etransmisiones from "./img/Ico-transmisiones.png";
import './Footer.css'


const Footer = () => {

    const cta1 = () => {
        window.location.href = "https://www.zapopan.gob.mx/v3/";
        };
    const cta5 = () => {
        window.location.href = "https://www.facebook.com/ZapopanGob/";
        };
    const cta6 = () => {
        window.location.href = "https://www.instagram.com/zapopangob/";
        };
    const cta7 = () => {
        window.location.href = "https://twitter.com/zapopangob/";
        };
    const cta8 = () => {
        window.location.href = "https://www.youtube.com/zapopangob";
        };
    const cta9 = () => {
        window.location.href = "https://livestream.com/zapopangob";
        };


    return (
        <footer>
        <div className="separador-morado1">
        </div>
        <div className="estilogralfooter">

                    <a href='https://www.zapopan.gob.mx/v3/' target='_blank'  rel="noreferrer" >
                        <img
                        className=" imagen-footer"
                        src={logocontraloria}
                        alt="Escudo zapopan en contraloría"/>
                    </a>
                    <div>
                        <ul className="ulsinvinetas tam ">
                            <li><a className="estiloenlace" target='_blank' rel="noreferrer"  href="https://www.zapopan.gob.mx/v3/circulares-interes-general">Circulares de interés general</a></li>
                            <li><a className="estiloenlace" target='_blank'rel="noreferrer"  href="https://www.zapopan.gob.mx/v3/accesibilidad">Accesibilidad del sitio</a></li>
                            <li><a className="estiloenlace" target='_blank'rel="noreferrer"   href="https://www.zapopan.gob.mx/v3/avisodeprivacidad">Aviso de privacidad</a></li>
                            <li>2021 - 2024 - Gobierno de Zapopan</li>

                        </ul>
                    </div>
                        <div>
                            <ul className="ulsinvinetas tam">
                                <li><a className="estiloenlace" target='_blank' rel="noreferrer" href="https://www.zapopan.gob.mx/v3/gobierno" > Gobierno</a></li>
                                <li><a className="estiloenlace" target='_blank'rel="noreferrer" href="https://vdigital.zapopan.gob.mx/">Trámites en línea</a></li>
                                <li><a className="estiloenlace" target='_blank' rel="noreferrer" href="https://www.zapopan.gob.mx/transparencia/"> Transparencia</a></li>
                                <li><a className="estiloenlace" target='_blank' rel="noreferrer" href="https://www.zapopan.gob.mx/v3/normatividad">  Normatividad</a></li>
                                <li><a className="estiloenlace" target='_blank' rel="noreferrer" href="https://www.zapopan.gob.mx/v3/ciudad"> Ciudad</a></li>
                            </ul>
                        </div>
                            <div>
                                <div className="tam iconos-redes">
                                    <button className="buttonlogo" onClick={cta5} >
                                        <img
                                        className="imglogos1"
                                        src={efacebook}
                                        alt="logo facebook"/>
                                    </button>
                                    <button className="buttonlogo" onClick={cta6}>
                                        <img
                                        className="imglogos1"
                                        src={einstragram}
                                        alt="logo instagram"/>
                                    </button>
                                    <button className="buttonlogo" onClick={cta7}>
                                        <img
                                        className="imglogos1"
                                        src={etwitter}
                                        alt="logo twiter"/>
                                    </button>
                                    <button className="buttonlogo" onClick={cta8}>
                                        <img
                                        className="imglogos1"
                                        src={eyoutube}
                                        alt="logo youtube"/>
                                    </button>
                                    <button className="buttonlogo" onClick={cta9}>
                                        <img
                                        className="imglogos1"
                                        src={etransmisiones}
                                        alt="logo livestream"/>
                                    </button>
                                </div>
                                <div className="tam tam1">
                                    Municipio de Zapopan, Jalisco
                                    Prolongación Avenida Laureles 300,
                                    Colonia Tepeyac C.P. 45150,
                                    Zapopan, Jalisco. México
                                    33 3818 2200
                                </div>
                            </div>
        </div>

        </footer>
    );
};

export default Footer;